@use '../../../../libs/it-ng-components/core/style/vars';

@mixin resets() {
  html,
  body {
    height: 100%;
  }

  html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    font-family: vars.$vmr-fonts-base;
  }

  // START: Material table overrides
  .mat-mdc-header-row {
    --mat-table-header-container-height: 52px;
  }

  .mat-mdc-row {
    --mat-table-row-item-container-height: 48px;
  }
  // END: Material table overrides

  // START: ng-doc style overrides
  h1,
  h2,
  h3,
  h4 {
    &.ngde {
      --ng-doc-font-weight: 500;
      font-family: var(--vermeer-font-header);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    &.ngde {
      margin: calc(var(--ng-doc-base-gutter) * 7) 0 0;
    }
  }

  h2 {
    &.ngde {
      --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 4.5);
    }

    code {
      --ng-doc-code-font-size: calc(var(--ng-doc-base-gutter) * 2.8);
    }
  }

  h3 {
    code {
      --ng-doc-code-font-size: calc(var(--ng-doc-base-gutter) * 2.25);
    }
  }

  .ng-doc-header {
    z-index: 220 !important;
  }

  .ng-doc-navbar-right {
    ng-doc-icon, .feather {
      width: 25px !important;
      height: 25px !important;
    }
  }

  .ng-doc-navbar-right {
    ng-doc-icon, .feather {
      width: 25px !important;
      height: 25px !important;
    }
  }

  ul.ngde, ol.ngde {
    margin: 20px 0;
  }

  .ng-doc-demo {
    padding: 10px;
  }

  .ng-doc-copy-button {
    --ng-doc-button-hover-background: transparent !important;

    opacity: 1 !important;
    top: 10px !important;
    right: 10px !important;

    ng-doc-icon,
    ng-doc-icon .feather-copy {
      width: 24px !important;
      height: 24px !important;
    }

    &:hover ng-doc-icon {
      color: var(--ng-doc-text);
    }
  }

  a.ngde {
    ng-doc-icon {
      vertical-align: text-top !important;
    }
  }

  .ng-doc-toc-wrapper {
    .ng-doc-toc-selection {
      width: 3px !important;
    }

    .ng-doc-toc {
      border-left-width: 3px !important;
      --ng-doc-text: var(--ng-doc-sidebar-category-color);

      > li {
        letter-spacing: -.01rem;
      }
    }
  }

  .ng-doc-tabs-wrapper {
    .ng-doc-tab {
      .ng-doc-tab-text {
        --ng-doc-font-weight: 500 !important;
      }
    }
  }

  .ng-doc-sidenav-content {
    min-height: 300px;
    ng-doc-breadcrumb {
      margin-bottom: calc(var(--ng-doc-base-gutter) * 2.5);
    }
  }

  .ng-doc-sidebar-category-button {
    span {
      letter-spacing: -0.00875rem;
    }

    .ng-doc-sidebar-category {
      &, &.active {
      --ng-doc-font-weight: 500 !important;
      }
    }
  }

  .ng-doc-sidebar-link {
    --ng-doc-sidebar-vetical-padding: 0;

    &.active {
      --ng-doc-text: var(--ng-doc-left-sidebar-link-active);
    }

    &:not(.active) {
      ng-doc-dot {
        --ng-doc-dot-background: var(--ng-doc-base-3);
      }
    }

    ng-doc-dot {
      width: 2.5px;
      height: 40px;
      border-radius: 0;
    }
  }

  // Hide the 'returns' section (redundant as 'presentation' section contains this info)
  .ng-doc-method-table {
    &.ngde {
      tbody > tr:last-of-type {
        display: none;
      }
    }
  }

  .ng-doc-properties-table {
    &.ngde {
      tbody, thead {
        > tr > td, th {
          &:nth-of-type(2) {
            code {
              max-width: 250px;
              text-wrap: wrap;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
  // END: ng-doc style overrides
}
