@use '@angular/material' as mat;
@use '../core/style/sass-utils' as utils;

@mixin background-color($theme) {
  $warn-color: mat.get-theme-color($theme, warn, 500);
  $accent-color: mat.get-theme-color($theme, accent, 500);
  $primary-color: mat.get-theme-color($theme, primary, 500);

  .vmr-progress-dots {
    &.mat-primary > div {
      background-color: utils.safe-color-change($primary-color, $alpha: 0.54);
    }

    &.mat-accent > div {
      background-color: utils.safe-color-change($accent-color, $alpha: 0.54);
    }

    &.mat-warn > div {
      background-color: utils.safe-color-change($warn-color, $alpha: 0.54);
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include background-color($theme);
  }
}
