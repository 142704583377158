@use 'sass:map';
@use '../core/style/vars';
@use '@angular/material' as mat;

@mixin color($theme) {
  .vmr-button .mat-mdc-button-base {
    &.mat-mdc-raised-button, &.mat-mdc-extended-fab {
      &.mat-primary {
        color: vars.$vmr-white;
        background-color: mat.get-theme-color($theme, primary, 500);
      }

      &.mat-warn {
        color: vars.$vmr-white;
        background-color: mat.get-theme-color($theme, warn, 500);
      }
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
