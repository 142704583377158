@import '@angular/cdk/overlay-prebuilt.css';
@import 'highlight.js/styles/github.css';
body {
  --ng-doc-code-margin: var(--ng-doc-page-block-margin);
  --ng-doc-blockquote-margin: var(--ng-doc-page-block-margin);
  --ng-doc-tabs-margin: var(--ng-doc-page-block-margin);
  --ng-doc-demo-margin: var(--ng-doc-page-block-margin);
  --ng-doc-demo-pane-margin: var(--ng-doc-page-block-margin);
  --ng-doc-playground-margin: var(--ng-doc-page-block-margin);
}

html {
  -webkit-font-smoothing: antialiased;
}

iframe.ngde {
  width: 100%;
  border: 0;
}

p.ngde {
  line-height: calc(var(--ng-doc-base-gutter) * 3);
  margin: calc(var(--ng-doc-base-gutter) * 2) 0;
}

ul.ngde,
ol.ngde {
  margin: var(--ng-doc-page-block-margin);
}

ul li.ngde,
ol li.ngde {
  line-height: 1.5rem;
  margin-bottom: calc(var(--ng-doc-base-gutter) + var(--ng-doc-base-gutter) / 2);
}

ul ul.ngde,
ol ul.ngde,
ul ol.ngde,
ol ol.ngde {
  margin-top: calc(var(--ng-doc-base-gutter) * 2);
  margin-bottom: 0;
}

blockquote.ngde {
  line-height: 1.5rem;
  margin: var(--ng-doc-page-block-margin);
}

h1.ngde,
h2.ngde,
h3.ngde,
h4.ngde,
h5.ngde,
h6.ngde {
  font-family: var(--ng-doc-heading-font-family);
  font-variant: no-contextual;
  color: var(--ng-doc-text);
  line-height: var(--ng-doc-line-height);
  font-size: var(--ng-doc-font-size);
  font-weight: var(--ng-doc-font-weight);
  margin: calc(var(--ng-doc-base-gutter) * 9) 0 0;
  --ng-doc-line-height: 1.5rem;
  --ng-doc-font-weight: 800;
  overflow-wrap: anywhere;
}
h1.ngde + h1.ngde, h1.ngde + h2.ngde, h1.ngde + h3.ngde, h1.ngde + h4.ngde, h1.ngde + h5.ngde, h1.ngde + h6.ngde,
h2.ngde + h1.ngde,
h2.ngde + h2.ngde,
h2.ngde + h3.ngde,
h2.ngde + h4.ngde,
h2.ngde + h5.ngde,
h2.ngde + h6.ngde,
h3.ngde + h1.ngde,
h3.ngde + h2.ngde,
h3.ngde + h3.ngde,
h3.ngde + h4.ngde,
h3.ngde + h5.ngde,
h3.ngde + h6.ngde,
h4.ngde + h1.ngde,
h4.ngde + h2.ngde,
h4.ngde + h3.ngde,
h4.ngde + h4.ngde,
h4.ngde + h5.ngde,
h4.ngde + h6.ngde,
h5.ngde + h1.ngde,
h5.ngde + h2.ngde,
h5.ngde + h3.ngde,
h5.ngde + h4.ngde,
h5.ngde + h5.ngde,
h5.ngde + h6.ngde,
h6.ngde + h1.ngde,
h6.ngde + h2.ngde,
h6.ngde + h3.ngde,
h6.ngde + h4.ngde,
h6.ngde + h5.ngde,
h6.ngde + h6.ngde {
  margin-top: calc(var(--ng-doc-base-gutter) * 2);
}

h1.ngde {
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 5);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 5);
  --ng-doc-font-weight: 900;
}

h2.ngde {
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 4);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 5);
}

h3.ngde {
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 3);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 4);
}

h4.ngde {
  --ng-doc-font-size: 20px;
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 4);
  margin: calc(var(--ng-doc-base-gutter) * 2) 0;
}

h5.ngde,
h6.ngde {
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 3);
  margin: calc(var(--ng-doc-base-gutter) * 2) 0;
}

pre.ngde {
  margin: var(--ng-doc-page-block-margin);
}
pre.ngde code.ngde {
  position: relative;
  white-space: unset;
  box-shadow: var(--ng-doc-code-shadow);
  font-family: var(--ng-doc-code-font);
  font-size: var(--ng-doc-code-font-size);
  font-weight: var(--ng-doc-code-font-weight);
  line-height: var(--ng-doc-code-line-height);
  border: var(--ng-doc-code-border);
  background-color: var(--ng-doc-code-background, inherit);
}

*:not(pre) > code.ngde {
  vertical-align: bottom;
  border-radius: calc(var(--ng-doc-base-gutter) / 2);
  color: var(--ng-doc-inline-code-color);
  padding: calc(var(--ng-doc-base-gutter) / 4) var(--ng-doc-base-gutter);
  font-family: var(--ng-doc-code-font);
  font-size: var(--ng-doc-code-font-size);
  font-weight: var(--ng-doc-code-font-weight);
  line-height: var(--ng-doc-line-height);
  white-space: nowrap;
}
*:not(pre) > code.ngde {
  position: relative;
}
*:not(pre) > code.ngde:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ng-doc-inline-code-background);
  opacity: 0.15;
  border-radius: inherit;
  overflow: hidden;
  pointer-events: none;
}

.ng-doc-table-wrapper {
  overflow-x: auto;
}

table.ngde {
  width: 100%;
  border-spacing: 0;
  margin: var(--ng-doc-page-block-margin);
}
table.ngde thead tr th {
  padding: var(--ng-doc-base-gutter);
  background-color: var(--ng-doc-base-1);
  text-align: left;
}
table.ngde thead tr th:first-child {
  border-top-left-radius: var(--ng-doc-base-gutter);
  border-bottom-left-radius: var(--ng-doc-base-gutter);
  padding-left: calc(var(--ng-doc-base-gutter) * 4);
}
table.ngde thead tr th:last-child {
  padding-right: calc(var(--ng-doc-base-gutter) * 4);
  border-top-right-radius: var(--ng-doc-base-gutter);
  border-bottom-right-radius: var(--ng-doc-base-gutter);
}
table.ngde thead tr th h1.ngde,
table.ngde thead tr th h2.ngde,
table.ngde thead tr th h3.ngde,
table.ngde thead tr th h4.ngde,
table.ngde thead tr th h5.ngde,
table.ngde thead tr th h6.ngde {
  margin-top: 0;
  margin-bottom: 0;
}
table.ngde tbody tr td {
  padding: calc(var(--ng-doc-base-gutter) * 2);
  vertical-align: top;
  border-bottom: 1px solid var(--ng-doc-base-1);
}
table.ngde tbody tr td:first-child {
  padding-left: calc(var(--ng-doc-base-gutter) * 4);
}
table.ngde tbody tr td:last-child {
  padding-right: calc(var(--ng-doc-base-gutter) * 4);
}
table.ngde tbody tr td table {
  margin: calc(var(--ng-doc-base-gutter) * 2) 0;
}
table.ngde h1.ngde,
table.ngde h2.ngde,
table.ngde h3.ngde,
table.ngde h4.ngde,
table.ngde h5.ngde,
table.ngde h6.ngde {
  margin: 0 0 calc(var(--ng-doc-base-gutter) * 2);
}

a.ngde {
  text-decoration: none;
  color: var(--ng-doc-link-color);
}
a.ngde:hover {
  text-decoration: underline;
}

mark.ng-doc-mark {
  background-color: var(--ng-doc-mark-background);
  color: var(--ng-doc-mark-color, inherit);
}

:root {
  --ng-doc-base-gutter: 8px;
  --ng-doc-transition: 0.2s;
  --ng-doc-text-selection: rgba(21, 88, 214, 0.15);
  --ng-doc-white: #fff;
  --ng-doc-black: #000;
  --ng-doc-base-0: var(--ng-doc-white);
  --ng-doc-base-1: #f6f6f6;
  --ng-doc-base-2: #ebebeb;
  --ng-doc-base-3: #dedede;
  --ng-doc-base-4: #d1d1d1;
  --ng-doc-base-5: #b8b8b8;
  --ng-doc-base-6: #ababab;
  --ng-doc-base-7: #9e9e9e;
  --ng-doc-base-8: #919191;
  --ng-doc-base-9: #858585;
  --ng-doc-base-10: #6e6e6e;
  --ng-doc-primary: #1558d6;
  --ng-doc-primary-text: var(--ng-doc-white);
  --ng-doc-alert: #ff5959;
  --ng-doc-alert-text: var(--ng-doc-white);
  --ng-doc-warning: #ff9f1a;
  --ng-doc-warning-text: var(--ng-doc-white);
  --ng-doc-info: #3f68b5;
  --ng-doc-info-text: var(--ng-doc-white);
  --ng-doc-background: var(--ng-doc-base-0);
  --ng-doc-hover-background: rgba(110, 110, 110, 0.09);
  --ng-doc-border-size: 1px;
  --ng-doc-border-color: var(--ng-doc-base-3);
  --ng-doc-border-hover-color: var(--ng-doc-base-5);
  --ng-doc-shadow-color: rgba(0, 0, 0, 0.15);
  --ng-doc-font-system: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
  	Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  	"Segoe UI Symbol", "Noto Color Emoji";
  --ng-doc-font-system-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
  	"Liberation Mono", "Courier New", monospace;
  --ng-doc-heading-font-family: var(--ng-doc-font-system);
  --ng-doc-font-family: var(--ng-doc-font-system);
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 3);
  --ng-doc-font-weight: 400;
  --ng-doc-code-font: var(--ng-doc-font-system-mono);
  --ng-doc-code-font-size: 14px;
  --ng-doc-code-line-height: 20px;
  --ng-doc-code-font-weight: 400;
  --ng-doc-text: #111111;
  --ng-doc-text-muted: var(--ng-doc-base-8);
  --ng-doc-mark-background: rgba(255, 222, 0, 0.3);
  --ng-doc-link-color: var(--ng-doc-primary);
  --ng-doc-inline-code-background: var(--ng-doc-base-9);
  --ng-doc-inline-code-color: var(--ng-doc-text);
  --ng-doc-button-background: trasparent;
  --ng-doc-button-background-opacity: 0;
  --ng-doc-button-color: var(--ng-doc-text);
  --ng-doc-button-hover-background: var(--ng-doc-base-1);
  --ng-doc-button-hover-color: var(--ng-doc-button-color);
  --ng-doc-button-active-background: var(--ng-doc-base-2);
  --ng-doc-button-active-color: var(--ng-doc-button-hover-color);
  --ng-doc-floated-border-color: transparent;
  --ng-doc-floated-border: 2px solid var(--ng-doc-floated-border-color);
  --ng-doc-floated-border-shadow-color: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px transparent;
  --ng-doc-floated-border-radius: var(--ng-doc-base-gutter);
  /* NgDocCheckboxComponent */
  --ng-doc-checkbox-color: var(--ng-doc-primary);
  --ng-doc-checkbox-color-text: var(--ng-doc-primary-text);
  --ng-doc-checkbox-border: var(--ng-doc-border-size) solid var(--ng-doc-border-color);
  --ng-doc-checkbox-border-hover: var(--ng-doc-border-size) solid var(--ng-doc-border-hover-color);
  /* NgDocInputWrapperComponent */
  --ng-doc-input-text-align: left;
  --ng-doc-input-width: 100%;
  --ng-doc-input-height: 40px;
  --ng-doc-input-border: var(--ng-doc-border-size) solid var(--ng-doc-border-color);
  --ng-doc-input-border-hover: var(--ng-doc-border-size) solid var(--ng-doc-border-hover-color);
  --ng-doc-input-background-color: var(--ng-doc-base-0);
  --ng-doc-input-padding-left: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-input-padding-right: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-input-cursor: auto;
  /* NgDocTagComponent */
  --ng-doc-tag-background: var(--ng-doc-primary);
  --ng-doc-tag-color: var(--ng-doc-primary-text);
  /* NgDocSelection */
  --ng-doc-selection-border: 3px solid var(--ng-doc-primary);
  --ng-doc-selection-background: transparent;
  /* NgDocDot */
  --ng-doc-dot-size: calc(var(--ng-doc-base-gutter) / 2);
  --ng-doc-dot-background: var(--ng-doc-primary);
  /* Sidenav */
  --ng-doc-sidenav-width: 300px;
  /* TabGroup */
  --ng-doc-tab-group-border-radius: var(--ng-doc-base-gutter);
  --ng-doc-tab-group-border: 1px solid var(--ng-doc-border-color);
}

.ng-doc-tooltip {
  --ng-doc-overlay-background: var(--ng-doc-tooltip-background, var(--ng-doc-base-10));
  --ng-doc-overlay-border: var(--ng-doc-tooltip-border, var(--ng-doc-base-10));
  --ng-doc-overlay-border-radius: calc(var(--ng-doc-base-gutter) / 2);
}
.ng-doc-tooltip .ng-doc-overlay-content {
  font-family: var(--ng-doc-font-family);
  font-variant: no-contextual;
  color: var(--ng-doc-text);
  line-height: var(--ng-doc-line-height);
  font-size: var(--ng-doc-font-size);
  font-weight: var(--ng-doc-font-weight);
  padding: var(--ng-doc-base-gutter) calc(var(--ng-doc-base-gutter) * 2);
  max-width: 400px;
  max-height: 50vh;
  white-space: pre-line;
  overflow-y: auto;
  --ng-doc-text: var(--ng-doc-base-0);
}
.ng-doc-tooltip .ng-doc-overlay-content p {
  margin: 0;
}

.ng-doc-notify {
  --ng-doc-overlay-background: var(--ng-doc-base-10);
  --ng-doc-overlay-border: var(--ng-doc-base-10);
  --ng-doc-overlay-border-radius: calc(var(--ng-doc-base-gutter) / 2);
}
.ng-doc-notify .ng-doc-overlay-content {
  font-family: var(--ng-doc-font-family);
  font-variant: no-contextual;
  color: var(--ng-doc-text);
  line-height: var(--ng-doc-line-height);
  font-size: var(--ng-doc-font-size);
  font-weight: var(--ng-doc-font-weight);
  padding: var(--ng-doc-base-gutter) calc(var(--ng-doc-base-gutter) * 2);
  max-width: 576px;
  max-height: 50vh;
  white-space: pre-line;
  overflow-y: auto;
  --ng-doc-text: var(--ng-doc-base-0);
}

.ng-doc-dialog {
  background: var(--ng-doc-background);
}

:root {
  --ng-doc-app-max-width: 1450px;
  --ng-doc-app-horizontal-padding: calc(var(--ng-doc-base-gutter) * 3);
  --ng-doc-code-background: var(--ng-doc-base-1);
  --ng-doc-page-padding: calc(var(--ng-doc-base-gutter) * 3) var(--ng-doc-app-horizontal-padding);
  --ng-doc-main-padding: var(--ng-doc-navbar-height) 0 0 0;
  --ng-doc-page-block-margin: calc(var(--ng-doc-base-gutter) * 3) 0;
  --ng-doc-class-color: #fff;
  --ng-doc-class-background: #e74c3c;
  --ng-doc-interface-color: #fff;
  --ng-doc-interface-background: #3498db;
  --ng-doc-enum-color: #fff;
  --ng-doc-enum-background: #2ecc71;
  --ng-doc-variable-color: #fff;
  --ng-doc-variable-background: #f1c40f;
  --ng-doc-function-color: #fff;
  --ng-doc-function-background: #27bd09;
  --ng-doc-type-alias-color: #fff;
  --ng-doc-type-alias-background: #3e69c5;
  --ng-doc-selector-color: #fff;
  --ng-doc-selector-background: #25ac5f;
  --ng-doc-string-color: #fff;
  --ng-doc-string-background: #ff1150;
  --ng-doc-number-color: #fff;
  --ng-doc-number-background: #3498db;
  --ng-doc-boolean-color: #fff;
  --ng-doc-boolean-background: #2ecc71;
  --ng-doc-null-color: #fff;
  --ng-doc-null-background: #95a5a6;
  --ng-doc-undefined-color: #fff;
  --ng-doc-undefined-background: #bdc3c7;
  --ng-doc-object-color: #fff;
  --ng-doc-object-background: #f39c12;
  --ng-doc-component-decorator-color: #fff;
  --ng-doc-component-decorator-background: #1abc9c;
  --ng-doc-directive-decorator-color: #fff;
  --ng-doc-directive-decorator-background: #e67e22;
  --ng-doc-injectable-decorator-color: #fff;
  --ng-doc-injectable-decorator-background: #9b59b6;
  --ng-doc-pipe-decorator-color: #fff;
  --ng-doc-pipe-decorator-background: #c0392b;
  --ng-doc-ng-module-decorator-color: #fff;
  --ng-doc-ng-module-decorator-background: #27ae60;
  --ng-doc-input-decorator-color: #fff;
  --ng-doc-input-decorator-background: #13a2da;
  --ng-doc-output-decorator-color: #fff;
  --ng-doc-output-decorator-background: #daa213;
  --ng-doc-abstract-background: #ff5f1a;
  --ng-doc-abstract-color: #fff;
  --ng-doc-async-background: #0de311;
  --ng-doc-async-color: #fff;
  --ng-doc-protected-background: var(--ng-doc-base-7);
  --ng-doc-protected-color: #fff;
  --ng-doc-static-background: #e30dbf;
  --ng-doc-static-color: #fff;
  --ng-doc-overriden-background: #0d78e3;
  --ng-doc-overriden-color: #fff;
  --ng-doc-readonly-background: #ff2020;
  --ng-doc-readonly-color: #fff;
  --ng-doc-get-background: #2ecc3b;
  --ng-doc-get-color: #fff;
  --ng-doc-set-background: #e30dbf;
  --ng-doc-set-color: #fff;
  --ng-doc-navbar-background: var(--ng-doc-base-0);
  --ng-doc-navbar-height: calc(var(--ng-doc-base-gutter) * 7);
  --ng-doc-navbar-horizontal-padding: var(--ng-doc-app-horizontal-padding);
  --ng-doc-navbar-left-width: calc(
  	var(--ng-doc-sidebar-width) - var(--ng-doc-navbar-horizontal-padding)
  );
  --ng-doc-sidebar-width: 310px;
  --ng-doc-sidebar-background: var(--ng-doc-base-0);
  --ng-doc-sidebar-padding: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-sidebar-vetical-padding: var(--ng-doc-base-gutter);
  --ng-doc-sidebar-horizontal-padding: var(--ng-doc-app-horizontal-padding);
  --ng-doc-demo-displayer-border: 1px solid var(--ng-doc-border-color);
  --ng-doc-demo-displayer-border-radius: var(--ng-doc-base-gutter);
  --ng-doc-code-max-height: 400px;
  --ng-doc-code-highlight-color: var(--ng-doc-primary);
  --ng-doc-toc-width: 280px;
  --ng-doc-toc-indent: var(--ng-doc-base-gutter);
  --ng-doc-search-result-color: #454545;
  --ng-doc-api-tag-color: #ff1111;
  --ng-doc-api-tag-background: rgba(255, 17, 17, 0.15);
  --ng-doc-guide-tag-color: #0086ff;
  --ng-doc-guide-tag-background: rgba(0, 134, 255, 0.15);
  /* Tabs */
  --ng-doc-tabs-margin: calc(var(--ng-doc-base-gutter) * 4) 0px;
  --ng-doc-tabs-border-radius: var(--ng-doc-base-gutter);
}

article header.ngde .ng-doc-page-tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: calc(var(--ng-doc-base-gutter) * 2);
  line-height: 22px;
}
article header.ngde h1 {
  margin-right: var(--ng-doc-base-gutter);
}
article header.ngde .ng-doc-header-details {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: var(--ng-doc-base-gutter);
  margin-top: var(--ng-doc-base-gutter);
  line-height: 18px;
}
article header.ngde .ng-doc-header-details code {
  --ng-doc-line-height: 18px;
}
article header.ngde .ng-doc-header-details .ng-doc-header-details-label {
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
  margin-left: calc(var(--ng-doc-base-gutter) * 2);
  opacity: 0.5;
}
article header.ngde .ng-doc-header-details .ng-doc-header-details-label:first-child {
  margin-left: 0;
}

.ng-doc-tags-wrapper {
  line-height: 22px;
}

.ng-doc-tag {
  display: inline-block;
  padding: 0 var(--ng-doc-base-gutter);
  font-size: 14px;
  border-radius: var(--ng-doc-base-gutter);
  background-color: var(--ng-doc-base-2);
  color: var(--ng-doc-text);
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 600;
}
.ng-doc-tag + .ng-doc-tag {
  margin-left: var(--ng-doc-base-gutter);
}
.ng-doc-tag[data-content=ng-doc-scope] {
  padding: 0;
  background-color: transparent;
  color: var(--ng-doc-text);
}
.ng-doc-tag[data-content=Class] {
  background-color: var(--ng-doc-class-background);
  color: var(--ng-doc-class-color);
}
.ng-doc-tag[data-content=Interface] {
  background-color: var(--ng-doc-interface-background);
  color: var(--ng-doc-interface-color);
}
.ng-doc-tag[data-content=Enum] {
  background-color: var(--ng-doc-enum-background);
  color: var(--ng-doc-enum-color);
}
.ng-doc-tag[data-content=Variable] {
  background-color: var(--ng-doc-variable-background);
  color: var(--ng-doc-variable-color);
}
.ng-doc-tag[data-content=Function] {
  background-color: var(--ng-doc-function-background);
  color: var(--ng-doc-function-color);
}
.ng-doc-tag[data-content=TypeAlias] {
  background-color: var(--ng-doc-type-alias-background);
  color: var(--ng-doc-type-alias-color);
}
.ng-doc-tag[data-content=Component] {
  background-color: var(--ng-doc-component-decorator-background);
  color: var(--ng-doc-component-decorator-color);
}
.ng-doc-tag[data-content=Directive] {
  background-color: var(--ng-doc-directive-decorator-background);
  color: var(--ng-doc-directive-decorator-color);
}
.ng-doc-tag[data-content=Injectable] {
  background-color: var(--ng-doc-injectable-decorator-background);
  color: var(--ng-doc-injectable-decorator-color);
}
.ng-doc-tag[data-content=Pipe] {
  background-color: var(--ng-doc-pipe-decorator-background);
  color: var(--ng-doc-pipe-decorator-color);
}
.ng-doc-tag[data-content=NgModule] {
  background-color: var(--ng-doc-ng-module-decorator-background);
  color: var(--ng-doc-ng-module-decorator-color);
}
.ng-doc-tag[data-content=ng-doc-tag-selector] {
  background-color: var(--ng-doc-selector-background);
  color: var(--ng-doc-selector-color);
}

.ng-doc-decorators-group {
  display: flex;
  gap: var(--ng-doc-base-gutter);
  margin-bottom: calc(var(--ng-doc-base-gutter) / 2);
}
.ng-doc-decorators-group code {
  --ng-doc-line-height: 18px;
}
.ng-doc-decorators-group.column {
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--ng-doc-base-gutter) / 2);
  margin-bottom: 0;
}
.ng-doc-decorators-group.column code {
  margin: 0;
}

.ng-doc-modifiers-wrapper {
  line-height: 18px;
}

.ng-doc-modifier {
  font-size: 14px;
  color: var(--ng-doc-text);
  font-family: var(--ng-doc-heading-font-family);
  white-space: nowrap;
  font-weight: 800;
}
.ng-doc-modifier[data-content=protected] {
  color: var(--ng-doc-protected-background);
}
.ng-doc-modifier[data-content=abstract] {
  color: var(--ng-doc-abstract-background);
}
.ng-doc-modifier[data-content=static] {
  color: var(--ng-doc-static-background);
}
.ng-doc-modifier[data-content=async] {
  color: var(--ng-doc-async-background);
}
.ng-doc-modifier[data-content=overriden] {
  color: var(--ng-doc-overriden-background);
}
.ng-doc-modifier[data-content=readonly] {
  color: var(--ng-doc-readonly-background);
}
.ng-doc-modifier[data-content=get] {
  font-size: 16px;
  color: var(--ng-doc-get-background);
}
.ng-doc-modifier[data-content=set] {
  font-size: 16px;
  color: var(--ng-doc-set-background);
}

.ng-doc-node-details {
  font-family: var(--ng-doc-font-family);
  font-variant: no-contextual;
  color: var(--ng-doc-text);
  line-height: var(--ng-doc-line-height);
  font-size: var(--ng-doc-font-size);
  font-weight: var(--ng-doc-font-weight);
  --ng-doc-code-line-height: var(--ng-doc-line-height);
  --ng-doc-font-size: 14px;
  --ng-doc-text: var(--ng-doc-text-muted);
  --ng-doc-font-weight: 400;
  --ng-doc-line-height: 22px;
}
.ng-doc-node-details code.ng-doc-code-with-link {
  --ng-doc-inline-code-background: transparent !important;
  padding: 0;
  --ng-doc-code-font-size: var(--ng-doc-font-size);
}

.ng-doc-badge-wrapper {
  display: inline-flex;
  margin-right: var(--ng-doc-base-gutter);
  vertical-align: middle;
}
.ng-doc-badge-wrapper .ng-doc-badge:first-child {
  border-top-left-radius: var(--ng-doc-base-gutter);
  border-bottom-left-radius: var(--ng-doc-base-gutter);
}
.ng-doc-badge-wrapper .ng-doc-badge:last-child {
  border-top-right-radius: var(--ng-doc-base-gutter);
  border-bottom-right-radius: var(--ng-doc-base-gutter);
}

.ng-doc-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--ng-doc-base-gutter) * 2);
  height: calc(var(--ng-doc-base-gutter) * 2);
  font-family: var(--ng-doc-heading-font-family);
  font-size: 10px;
  color: var(--ng-doc-white);
  white-space: nowrap;
  font-weight: 900;
  background-color: var(--ng-doc-text);
  text-transform: capitalize;
}
.ng-doc-badge[data-content=protected] {
  background-color: var(--ng-doc-protected-background);
  color: var(--ng-doc-protected-color);
}
.ng-doc-badge[data-content=abstract] {
  background-color: var(--ng-doc-abstract-background);
  color: var(--ng-doc-abstract-color);
}
.ng-doc-badge[data-content=static] {
  background-color: var(--ng-doc-static-background);
  color: var(--ng-doc-static-color);
}
.ng-doc-badge[data-content=async] {
  background-color: var(--ng-doc-async-background);
  color: var(--ng-doc-async-color);
}
.ng-doc-badge[data-content=overriden] {
  background-color: var(--ng-doc-overriden-background);
  color: var(--ng-doc-overriden-color);
}
.ng-doc-badge[data-content=readonly] {
  background-color: var(--ng-doc-readonly-background);
  color: var(--ng-doc-readonly-color);
}

.ng-doc-see-also li {
  margin: var(--ng-doc-base-gutter) 0;
}
.ng-doc-see-also li p {
  margin: 0;
}

table.ng-doc-properties-table .ng-doc-properties-table-name {
  width: 25%;
}
table.ng-doc-properties-table .ng-doc-properties-table-type {
  width: 25%;
}

table.ng-doc-parameters-table .ng-doc-parameters-table-name {
  width: 25%;
}
table.ng-doc-parameters-table .ng-doc-parameters-table-type {
  width: 25%;
}

.ng-doc-no-content {
  color: var(--ng-doc-text-muted);
  font-style: italic;
  font-size: 14px;
}

code.ng-doc-code-with-link {
  --ng-doc-inline-code-background: var(--ng-doc-link-color);
}

article h1:hover .ng-doc-header-link,
article h2:hover .ng-doc-header-link,
article h3:hover .ng-doc-header-link,
article h4:hover .ng-doc-header-link,
article h5:hover .ng-doc-header-link,
article h6:hover .ng-doc-header-link {
  opacity: 1;
}
article h1 .ng-doc-header-link,
article h2 .ng-doc-header-link,
article h3 .ng-doc-header-link,
article h4 .ng-doc-header-link,
article h5 .ng-doc-header-link,
article h6 .ng-doc-header-link {
  opacity: 0;
  transition: var(--ng-doc-transition);
}
article .ng-doc-page-wrapper > h1:first-child,
article .ng-doc-page-wrapper > h2:first-child,
article .ng-doc-page-wrapper > h3:first-child,
article .ng-doc-page-wrapper > h4:first-child,
article .ng-doc-page-wrapper > h5:first-child,
article .ng-doc-page-wrapper > h6:first-child {
  margin-top: 0;
}
article section.ngde {
  margin-bottom: calc(var(--ng-doc-base-gutter) * 5);
}
article header.ngde {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: calc(var(--ng-doc-base-gutter) * 3);
}
article header.ngde .ng-doc-page-tags {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--ng-doc-base-gutter);
  margin-bottom: calc(var(--ng-doc-base-gutter) * 2);
  line-height: 22px;
}
article header.ngde h1.ngde {
  margin: 0;
  margin-right: var(--ng-doc-base-gutter);
}
article th p.ngde,
article td p.ngde {
  margin: 0;
}
article .ng-doc-inline-header {
  display: flex;
  align-items: center;
}
article .ng-doc-inline-header h1,
article .ng-doc-inline-header h2,
article .ng-doc-inline-header h3,
article .ng-doc-inline-header h4,
article .ng-doc-inline-header h5,
article .ng-doc-inline-header h6 {
  margin-right: var(--ng-doc-base-gutter);
}
article .ng-doc-inline-delimiter {
  font-weight: 700;
  font-size: 14px;
  opacity: 0.2;
}
article .ng-doc-header-link {
  display: inline-flex;
  margin-left: var(--ng-doc-base-gutter);
  vertical-align: middle;
}
article pre code .ng-doc-code-anchor {
  color: inherit;
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

html,
body {
  font-family: var(--ng-doc-font-family);
  font-variant: no-contextual;
  color: var(--ng-doc-text);
  line-height: var(--ng-doc-line-height);
  font-size: var(--ng-doc-font-size);
  font-weight: var(--ng-doc-font-weight);
  font-size: var(--ng-doc-font-size);
  background-color: var(--ng-doc-background);
  margin: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: var(--ng-doc-base-gutter);
  height: var(--ng-doc-base-gutter);
}

/* Track */
::-webkit-scrollbar-track:hover {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ng-doc-base-5);
  border-radius: calc(var(--ng-doc-base-gutter) * 2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ng-doc-base-7);
}

::selection {
  background: var(--ng-doc-text-selection);
}
