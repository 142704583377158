@use './vars';

// Mixin that shrinks material height defaults down to Vermeer default (40px).
// Include inside class `.mat-mdc-form-field`.
@mixin normalize-form-field-height() {
  .mat-mdc-form-field-flex {
    height: vars.$vmr-control-height;

    .mat-mdc-form-field-infix {
      min-height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: calc(#{vars.$vmr-control-height} / 2);
    }
  }
}

// Mixin that includes `normalize-form-field-height` mixin + adjusts border-radius to vermeer specs
@mixin apply-vmr-form-field-specs() {
  .mat-mdc-form-field {
    --mdc-outlined-text-field-container-shape: #{vars.$vmr-container-shape};
    @include normalize-form-field-height();
  }
}

// TODO: refactor using proper scss syntax to clean this up - this will be used in global style sheets and possibly in prebuilt themes to override form-field CSS
@mixin normalize-non-textarea-form-fields() {
  .mat-mdc-form-field {
    --mdc-outlined-text-field-container-shape: #{vars.$vmr-container-shape};
    &:not(:has(.mat-mdc-form-field-textarea-control)) {
      @include normalize-form-field-height();
    }
  }
}
