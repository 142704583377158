@use '../core/style/vars';

@mixin border-radius($theme) {
  .vmr-skeleton-container {
    &.vmr-skeleton-circle {
      border-radius: 50%;
    }
  }
}

@mixin background-color($theme) {
  .vmr-skeleton-container {
    background: rgb(238, 240, 245);
  }
}

@mixin pseudo-background($theme) {
  .vmr-skeleton-container {
    &.vmr-skeleton-progress::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0)
      );
    }
  }
}

@mixin theme($theme) {
  @include border-radius($theme);
  @include background-color($theme);
  @include pseudo-background($theme);
}
