@use 'sass:map';
@use '../core/style/vars';
@use '@angular/material' as mat;

@mixin color($theme) {
  $primary-color: mat.get-theme-color($theme, primary, 500);
  $accent-color: mat.get-theme-color($theme, accent, 500);
  $warn-color: mat.get-theme-color($theme, warn, 500);

  .vmr-badge-content {
    outline-width: 1px;
    outline-style: solid;

    .mat-primary & {
      color: vars.$vmr-white;
      background-color: $primary-color;
      outline-color: $primary-color;
    }

    .mat-warn & {
      color: vars.$vmr-white;
      background-color: $warn-color;
      outline-color: $warn-color;
    }

    .mat-accent & {
      color: vars.$vmr-white;
      background-color: $accent-color;
      outline-color: $accent-color;
    }

    .mat-white & {
      color: $primary-color;
      background-color: vars.$vmr-white;
      outline-color: $primary-color;
    }

    .mat-info & {
      color: vars.$vmr-white;
      background-color: vars.$vmr-notification-blue;
      outline-color: vars.$vmr-notification-blue;
    }

    .mat-success & {
      color: vars.$vmr-white;
      background-color: vars.$vmr-action-green;
      outline-color: vars.$vmr-action-green;
    }

    .mat-secondary & {
      color: vars.$vmr-black;
      background-color: vars.$vmr-accent;
      outline-color: vars.$vmr-accent;
    }

    .mat-grey & {
      color: vars.$vmr-white;
      background-color: vars.$vmr-grey;
      outline-color: vars.$vmr-grey;
    }

    .mat-transparent & {
      color: currentColor;
      background-color: transparent;
      outline-color: transparent;
    }

    .vmr-badge-disabled & {
      opacity: .82;
      color: vars.$vmr-white;
      background-color: vars.$vmr-grey;
      outline-color: vars.$vmr-grey;
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
