@use 'sass:map';
@use '../core/style/vars';
@use '@angular/material' as mat;
@use '../core/style/sass-utils' as utils;

// PRIVATE MIXINS
@mixin _private-switch-icon-color($color) {
  &.vmr-switch-checked .vmr-switch-icon {
    &.icon-checked svg {
      opacity: 1;
      color: $color;
    }

    &.icon-unchecked svg {
      opacity: 0;
      color: $color;
    }
  }
}

@mixin _private-root-switch-color-config($color) {
  &:not(.vmr-switch-checked) .vmr-switch-track::before {
    border-color: #B1B4BC;
    background-color: $color;
  }

  &:not(.vmr-switch-checked) .vmr-switch-icon {
    &.icon-checked svg {
      opacity: 0;
      color: $color;
    }

    &.icon-unchecked svg {
      opacity: 1;
      color: $color;
    }
  }

  &.vmr-switch-disabled {
    &:not(.vmr-switch-checked) .vmr-switch-track::before {
      background-color: #A3A5AF;
    }

    .vmr-switch-track {
      opacity: var(--mdc-switch-disabled-track-opacity);
    }

    .vmr-switch-handle,
    .vmr-switch-icons {
      opacity: .38;
    }
  }
}

// EXPORTED THEME STYLES (TODO: clean this up)
@mixin color($theme) {
  $unchecked-color: #e0e2ec;
  $primary-color: mat.get-theme-color($theme, primary, 500);
  $accent-color: mat.get-theme-color($theme, accent, 500);
  $warn-color: mat.get-theme-color($theme, warn, 500);

  .vmr-switch {
    --mdc-switch-disabled-track-opacity: .34;

    &.mat-primary {
      &.vmr-switch-checked .vmr-switch-track::before {
        border-color: transparent;
        background-color: $primary-color;
      }
      @include _private-switch-icon-color($primary-color);
    }

    &.mat-accent {
      &.vmr-switch-checked .vmr-switch-track::before {
        border-color: transparent;
        background-color: $accent-color;
      }
      @include _private-switch-icon-color($accent-color);
    }

    &.mat-warn {
      &.vmr-switch-checked .vmr-switch-track::before {
        border-color: transparent;
        background-color: $warn-color;
      }
      @include _private-switch-icon-color($warn-color);
    }

    @include _private-root-switch-color-config($unchecked-color);
  }
}


@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
