@use '../core/style/vars';
@use '@angular/material' as mat;

@mixin color($theme) {
  .vmr-progress-bar {
    &.mat-primary .vmr-linear-progress__bar-inner {
      border-color: mat.get-theme-color($theme, primary, 500);
    }

    &.mat-warn .vmr-linear-progress__bar-inner {
      border-color: mat.get-theme-color($theme, warn, 500);
    }

    &.mat-accent .vmr-linear-progress__bar-inner {
      border-color: mat.get-theme-color($theme, accent, 500);
    }

    &.mat-white .vmr-linear-progress__bar-inner {
      border-color: vars.$vmr-white;
    }

    &.mat-info .vmr-linear-progress__bar-inner {
      border-color: vars.$vmr-notification-blue;
    }

    &.mat-success .vmr-linear-progress__bar-inner {
      border-color: vars.$vmr-action-green;
    }

    &.mat-secondary .vmr-linear-progress__bar-inner {
      border-color: vars.$vmr-accent;
    }

    &.mat-grey .vmr-linear-progress__bar-inner {
      border-color: vars.$vmr-grey;
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
